import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-progress-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-indicator.component.html',
  styleUrl: './progress-indicator.component.scss',
})
export class ProgressIndicatorComponent implements OnChanges, OnInit {
  @Input() currentStep: number = 0;
  @Input() numberOfSteps: number = 1;
  @Input() isLogged?: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (this.isLogged) {
      if (changes['currentStep'].currentValue > 0) {
        this.currentStep = changes['currentStep'].currentValue - 1;
      }
    }
  }

  progressPercent() {
    return Math.round((this.currentStep / this.numberOfSteps) * 100);
  }

  ngOnInit(): void {
    if (this.isLogged) {
      this.numberOfSteps--;
    }
  }
}
